






import { Component, Prop, Vue } from "vue-property-decorator";
import dayjs from "dayjs";
import "dayjs/locale/ru";

dayjs.locale("ru");

@Component
export default class DateTimeView extends Vue {
  @Prop({ required: true })
  readonly datetimestring!: string;

  @Prop({ default: "DD.MM.YYYY" })
  readonly format!: string;

  get datetime(): string {
    return dayjs(this.datetimestring).format(this.format);
  }
}
