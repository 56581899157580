






















































import { Component, Vue, Watch } from "vue-property-decorator";
import { isMobile } from "mobile-device-detect";

import DateTime from "@/view/content/DateTime.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

import { IndicatorVm } from "@/api/api";

import { plotModule } from "@/store";

@Component({
  components: {
    DatePicker,
    DateTime,
  },
})
export default class Indicators extends Vue {
  get indicators(): { [key: string]: IndicatorVm[] } {
    return plotModule.getters.groupedIndicators;
  }

  get selectedIndicators(): IndicatorVm[] {
    return plotModule.state.selectedIndicators;
  }

  selectIndicator(indicator: IndicatorVm) {
    plotModule.actions.selectIndicator(indicator);
  }

  switchIndicator(indicator: IndicatorVm) {
    plotModule.actions.switchIndicator(indicator);
  }

  isSelected(indicator: IndicatorVm) {
    return this.selectedIndicators.includes(indicator);
  }

  @Watch("indicators")
  indicatorsChanged(): void {
    if (Object.keys(this.indicators).length && !isMobile) {
      const indicators = Object.values(this.indicators)
        .reduce((a, b) => a.concat(b))
        .filter((i, index) => index < 3);

      indicators.forEach((i) => {
        plotModule.actions.switchIndicator(i);
      });
    }
  }

  get isMobile() {
    return isMobile;
  }
}
