





























import { Component, Vue, Prop } from "vue-property-decorator";

import DateTime from "@/view/content/DateTime.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

import { IndicatorVm, Serie } from "@/api/api";
import { plotModule } from "@/store";

@Component({
  components: {
    DatePicker,
    DateTime,
  },
})
export default class TableResult extends Vue {
  @Prop()
  indicator!: IndicatorVm;

  get series(): Serie[] {
    return (
      plotModule.getters.series.find((s) => s.indicator == this.indicator)
        ?.series || []
    );
  }

  get dimension() {
    return this.series[0].dimension;
  }
}
