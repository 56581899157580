



























































































import { Component, Vue, Prop } from "vue-property-decorator";

import DateTime from "@/view/content/DateTime.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

import { IndicatorVm, NormStatus, Serie } from "@/api/api";
import dayjs from "dayjs";

import { plotModule } from "@/store";

@Component({
  components: {
    DatePicker,
    DateTime,
  },
})
export default class TableResult extends Vue {
  NormStatus = NormStatus;

  get selectedIndicators(): IndicatorVm[] {
    return plotModule.state.selectedIndicators;
  }
  get series(): {
    indicator: IndicatorVm;
    series: Serie[];
  }[] {
    return plotModule.state.indicatorsSeries;
  }

  get dateRange() {
    if (!this.series || !this.series.length) return [];
    const dates = this.series
      .filter((s) => this.selectedIndicators.includes(s.indicator))
      .map((s) =>
        s.series
          .map((s) => s.data)
          .reduce((a, b) => a!.concat(b))!
          .map((p: any) => p.x)
      )
      .reduce((a, b) => a.concat(b), [])
      .sort((a: string, b: string) => (dayjs(b).isAfter(dayjs(a)) ? -1 : 1));
    return [...new Set(dates)];
  }

  getIndicatorKey(indicator: IndicatorVm) {
    return (
      indicator.CommonIndicatorId! +
      indicator.StudyTypeId! +
      indicator.BiomaterialId!
    );
  }

  getIndicatorValueByDate(
    indicator: IndicatorVm,
    date: string
  ): {
    x: string;
    y: number;
    caption: string;
    norm: string;
    alert: boolean;
    statusText: string;
    status: NormStatus;
    fillColor: string;
  }[] {
    const series = this.series.find((s) => s.indicator == indicator)?.series;
    return (
      series
        ?.map((x) => x.data!.filter((d) => d.x === date))
        .reduce((a, b) => a.concat(b)) || []
    );
  }

  getIndicatorSerieDimension(indicator: IndicatorVm): string {
    const series = this.series.find((s) => s.indicator == indicator)?.series;
    if (series == undefined) return "";
    return series[0]?.dimension || "-";
  }

  isLineIndicator(indicator: IndicatorVm) {
    if (this.series == undefined) return false;
    const series = this.series.find((s) => s.indicator == indicator)?.series;
    if (series == undefined) return false;
    return series[0].type === "line";
  }

  tooltip(
    indicatorName: string,
    point: {
      x: string;
      y: number;
      caption: string;
      norm: string;
      alert: boolean;
      statusText: string;
      fillColor: string;
    },
    dimension: string
  ) {
    const pointData = point;
    return (
      "<div>" +
      `<span>${indicatorName}: <b>${
        point.caption || point.y
      }</b> <small>${dimension}</small></span></span><br/>` +
      `<span class="${pointData.alert ? "text-danger" : "text-success"}">${
        pointData.statusText
      }</span><br/>` +
      (pointData.norm.length
        ? `<span>Норма: ${pointData.norm} ${dimension}</span>`
        : "") +
      "</div>"
    );
  }
}
