














































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { isMobile } from "mobile-device-detect";

import DateTime from "@/view/content/DateTime.vue";
import Plot from "./components/Plot.vue";
import TableResult from "./components/TableResult.mobile.vue";
import TableDesktopResult from "./components/TableResult.desktop.vue";
import Indicators from "./components/Indicators.vue";

import { plotModule, profileModule } from "@/store";

import { IndicatorVm, PatientVm, Serie } from "@/api/api";
import helpers from "@/services/helper";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import Swal from "sweetalert2";

@Component({
  components: {
    Plot,
    TableResult,
    TableDesktopResult,
    DatePicker,
    Indicators,
    DateTime,
  },
})
export default class Plots extends Vue {
  showAsList = true;
  getYears = helpers.getYears;

  selectPatient(patient: PatientVm) {
    profileModule.mutations.setPatient(patient);
  }

  async sendToEmail() {
    if (!this.patient || !this.patient!.isEmailConfirmed) {
      Swal.fire({
        text:
          "Для получения результатов на почту, необходимо указать и подтвердить Email.",
        icon: "warning",
        confirmButtonText: "Перейти в настройки",
        denyButtonText: "Закрыть",
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ name: "profile" });
        }
      });
      return;
    }

    const blobPDF = await this.getReport();

    plotModule.actions
      .sendToEmail({
        file: blobPDF,
        patientId: this.patient!.patientId!,
      })
      .then(() => {
        Swal.fire({ text: "Результаты отправлены на почту", icon: "success" });
      });
  }

  async downloadPdf() {
    const blobPDF = await this.getReport();
    const blobUrl = URL.createObjectURL(blobPDF);
    helpers.saveData(blobUrl, `динамика_${dayjs().format("DDMMYYYY")}.pdf`);
  }

  private async getReport() {
    const doc = new jsPDF();

    const font = await fetch("/OpenSans.ttf")
      .then((response) => response.blob())
      .then((response) => helpers.getBase64(response))
      .then((response) => {
        return response.split(",")[1];
      });

    doc.addFileToVFS("Font.ttf", font);
    doc.addFont("Font.ttf", "font", "normal");

    doc.setFont("font");

    doc.setFontSize(15);
    const patientFio = `${this.patient!.secondName} ${
      this.patient!.firstName
    } ${this.patient!.patronymic}`.trimRight();
    doc.text(patientFio, 10, 10);
    doc.setFontSize(12);
    const patientBirthDate = dayjs(this.patient!.birthDay).format("DD.MM.YYYY");
    doc.text(`Дата рождения: ${patientBirthDate}`, 10, 15);

    doc.text(
      `Результаты: ${this.selectedIndicators.length} ${this.tests}`,
      15,
      25
    );

    doc.setFontSize(10);
    // doc.text(`Период:`, 15, 35);
    // doc.text(
    //   `${dayjs(this.filter.DateRange[0]).format("DD.MM.YYYY")} — ${dayjs(
    //     this.filter.DateRange[1]
    //   ).format("DD.MM.YYYY")}`,
    //   45,
    //   35
    // );
    doc.text(`Биоматериал:`, 15, 40);

    doc.text(
      `${[
        ...new Set(this.selectedIndicators.map((i) => i.BiomaterialName)),
      ].join(", ")}`,
      45,
      40
    );

    doc.setFontSize(6);

    doc.setDrawColor(0);
    doc.setFillColor(255, 0, 0);
    doc.rect(167, 42, 2, 2, "F");
    doc.text(`Отклонение`, 170, 44);

    doc.setDrawColor(0);
    doc.setFillColor(0, 255, 0);
    doc.circle(188, 43, 1, "F");
    doc.text(`Норма`, 190, 44);

    doc.setFontSize(8);

    for (let i = 0; i < this.selectedIndicators.length; i++) {
      const indicator = this.selectedIndicators[i];
      const plotid = `plot${indicator.CommonIndicatorId}${indicator.StudyTypeId}${indicator.BiomaterialId}`;
      const { imgURI, blob } = await this.$apexcharts.exec(plotid, "dataURI", {
        width: 1000,
      });
      doc.addImage(
        imgURI as string,
        "PNG",
        10,
        45 + i * 30,
        190,
        this.isMobile ? 0 : 24
      );
      doc.text(indicator.IndicatorName!, 15, 45 + i * 30);
    }
    return doc.output("blob");
  }

  get tests() {
    const count = this.selectedIndicators.length.toString();
    if (count.endsWith("1")) return "тест";
    if (count.endsWith("2") || count.endsWith("3") || count.endsWith("4"))
      return "теста";
    return "тестов";
  }

  get patients(): PatientVm[] {
    return profileModule.state.patients;
  }

  get patient(): PatientVm | null {
    return profileModule.state.patient;
  }

  get indicators(): { [key: string]: IndicatorVm[] } {
    return plotModule.getters.groupedIndicators;
  }

  get selectedIndicators(): IndicatorVm[] {
    return plotModule.state.selectedIndicators;
  }

  get isMobile() {
    return isMobile;
  }

  selectIndicator(indicator: IndicatorVm) {
    plotModule.actions.selectIndicator(indicator);
  }

  mounted() {
    if (this.patient == null) return;
    plotModule.actions.loadIndicators({
      patientId: this.patient.patientId!,
    });
  }

  @Watch("patient")
  patientChanged(): void {
    plotModule.actions.selectIndicator(null);
    if (this.patient == null) return;
    plotModule.actions.loadIndicators({
      patientId: this.patient.patientId!,
    });
  }
}
