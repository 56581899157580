






























import { Component, Vue, Prop } from "vue-property-decorator";

import DateTime from "@/view/content/DateTime.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

import ru from "apexcharts/dist/locales/ru.json";
import { IndicatorVm, Serie } from "@/api/api";

@Component({
  components: {
    DatePicker,
    DateTime,
  },
})
export default class Plot extends Vue {
  @Prop()
  indicator!: IndicatorVm;

  @Prop({ default: false })
  isMobile!: boolean;

  get series(): Serie[] {
    return (
      (this.$store.getters["plot/series"] as {
        indicator: IndicatorVm;
        series: Serie[];
      }[]).find((s) => s.indicator == this.indicator)?.series || []
    );
  }

  get dimension() {
    return this.series[0].dimension;
  }

  get plotid() {
    return `plot${this.indicator.CommonIndicatorId}${this.indicator.StudyTypeId}${this.indicator.BiomaterialId}`;
  }

  get min() {
    const minDate = this.series
      .map(function (value: Serie) {
        return value.data!.length ? new Date(value.data![0].x) : new Date();
      })
      .reduce(function (a: Date, b: Date) {
        return a < b ? a : b;
      });
    minDate.setHours(0, 0, 0, 0);
    const prevDay = new Date(minDate);
    prevDay.setDate(prevDay.getDate() - 1);
    return prevDay;
  }

  get max() {
    const min = this.min;
    const maxDate = this.series
      .map(function (value) {
        //if(value.type === "line" || value.type === "scatter")
        return value.data!.length > 1
          ? new Date(value.data!.slice(-1)[0].x)
          : value.data!.length
          ? new Date(value.data![0].x)
          : min;
      })
      .reduce(function (a, b) {
        return a > b ? a : b;
      });
    maxDate.setHours(0, 0, 0, 0);
    const nextDay = new Date(maxDate);
    nextDay.setDate(nextDay.getDate() + 1);
    return nextDay;
  }

  get options() {
    if (this.series[0].type === "line") {
      const options = {
        annotations: {
          points: [
            ...this.series[0].data!.map(function (p, index) {
              return {
                x: new Date(p.x).getTime(),
                y: p.y,
                marker: {
                  size: p.alert ? 8 : 6,
                  fillColor: p.fillColor,
                  shape: p.alert ? "square" : "circle",
                  radius: 0,
                  strokeWidth: 0,
                  style: {
                    fontSize: "14px",
                    fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                    colors: ["#808080"],
                  },
                },
              };
            }),
          ],
        },
        chart: {
          locales: [ru],
          defaultLocale: "ru",
          id: this.plotid,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          events: {
            dataPointMouseEnter: function (event: any) {
              event.path[0].style.cursor = "pointer";
            },
          },
        },
        colors: ["#008000", "#FF0000", "#008000", "#A0522D"],
        dataLabels: {
          enabled: true,
          offsetY: -7,
          background: { enabled: false },
          style: {
            fontSize: "14px",
            fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: ["#808080"],
          },
        },
        stroke: {
          curve: "smooth",
          width: 3,
          colors: ["#ccc"],
          dashArray: 3,
        },
        title: {
          text: undefined,
          align: "left",
          style: {
            fontSize: "12px",
            fontWeight: 600,
            fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
            colors: ["#ccc"],
          },
        },
        grid: {
          show: true,
          borderColor: "#e2e2e2",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          enabled: true,
          custom: (props: {
            series: any[];
            seriesIndex: number;
            dataPointIndex: number;
            w: any;
          }) => {
            const pointData = this.series[props.seriesIndex].data![
              props.dataPointIndex
            ];
            return (
              '<div class="arrow_box">' +
              `<span>${this.indicator.IndicatorName}: <b>${
                props.series[props.seriesIndex][props.dataPointIndex]
              }</b> <small>${this.dimension}</small></span></span><br/>` +
              `<span class="${
                pointData.alert ? "text-danger" : "text-success"
              }">${pointData.statusText}</span><br/>` +
              (pointData.norm.length
                ? `<span>Норма: ${pointData.norm} ${this.dimension}</span>`
                : "") +
              "</div>"
            );
          },
        },
        markers: {
          show: false,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        xaxis: {
          type: "datetime",
          min: new Date(this.min).getTime(),
          max: new Date(this.max).getTime(),
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
              fontWeight: "normal",
            },
            datetimeUTC: false,
            datetimeFormatter: {
              year: "yyyy",
              month: "dd MMM",
              day: "dd MMM",
              hour: "HH:mm",
            },
          },
        },

        yaxis: [
          {
            show: true,
            opposite: false,
            labels: {
              show: true,
              style: {
                colors: ["#ccc"],
              },
            },
            axisBorder: { show: false },
          },
        ],
        legend: {
          show: false,
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      };

      if (!this.isMobile) {
        options.yaxis.push({
          opposite: true,
          labels: { show: false },
          axisBorder: { show: false },
          title: {
            text: this.dimension,
            rotate: -90,
            offsetX: -5,
            style: {
              color: "#808080",
              fontSize: "12px",
              fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
              fontWeight: "normal",
              cssClass: "apexcharts-yaxis-title",
            },
          },
        } as any);
      }
      return options;
    }

    return {
      chart: {
        locales: [ru],
        defaultLocale: "ru",
        id: this.plotid,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        events: {
          dataPointMouseEnter: function (event: any) {
            event.path[0].style.cursor = "pointer";
          },
        },
      },
      colors: ["#008000", "#FF0000", "#008000", "#A0522D"],
      dataLabels: {
        enabled: true,
        textAnchor: "start",
        offsetY: -7,
        offsetX: 0,
        background: { enabled: false },
        style: {
          fontSize: "14px",
          fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
          fontWeight: "bold",
          colors: ["#808080"],
        },
        formatter: (
          val: any,
          props: {
            series: any[];
            seriesIndex: number;
            dataPointIndex: number;
            w: any;
          }
        ) => {
          const pointData = this.series[props.seriesIndex].data![
            props.dataPointIndex
          ];
          return pointData.caption;
        },
      },
      grid: {
        show: true,
        borderColor: "#e2e2e2",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        enabled: true,
        custom: (props: {
          series: any[];
          seriesIndex: number;
          dataPointIndex: number;
          w: any;
        }) => {
          const pointData = this.series[props.seriesIndex].data![
            props.dataPointIndex
          ];
          return (
            '<div class="arrow_box">' +
            `<span>${this.indicator.IndicatorName}: <b>${pointData.caption}</b> <small>${this.dimension}</small></span></span><br/>` +
            `<span class="${
              pointData.alert ? "text-danger" : "text-success"
            }">${pointData.statusText}</span><br/>` +
            (pointData.norm.length
              ? `<span>Норма: ${pointData.norm} ${this.dimension}</span>`
              : "") +
            "</div>"
          );
        },
      },
      markers: {
        show: true,
        size: [6, 8],
        width: [6, 12],
        height: [6, 12],
        shape: ["circle", "rect"],
        radius: 0,
        strokeWidth: 0,
        hover: {
          sizeOffset: 0,
        },
      },
      xaxis: {
        type: "datetime",
        min: new Date(this.min).getTime(),
        max: new Date(this.max).getTime(),
        labels: {
          style: {
            fontSize: "12px",
            fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
            fontWeight: "normal",
          },
          datetimeUTC: false,
          datetimeFormatter: {
            year: "yyyy",
            month: "dd MMM",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
      },

      yaxis: [
        {
          show: true,
          opposite: false,
          max: 1.5,
          labels: {
            show: true,
            formatter: (value: any) => {
              return "  ";
            },
            style: {
              colors: ["#ccc"],
            },
          },
          axisBorder: { show: false },
        },
        {
          opposite: true,
          labels: { show: false },
          axisBorder: { show: false },
          title: {
            text: this.dimension || " ",
            rotate: -90,
            offsetX: -10,
            style: {
              color: "#808080",
              fontSize: "12px",
              fontFamily: "Open Sans, Helvetica, Arial, sans-serif",
              fontWeight: "normal",
              cssClass: "apexcharts-yaxis-title",
            },
          },
        },
      ],
      legend: {
        show: false,
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    };
  }
}
